import { Alert, SecondaryButton } from '@designsforhealth/dfh-react-components'
import { useLocation } from '@gatsbyjs/reach-router'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import copyIcon from '../../img/copy.png'

const StyledCopyArea = styled(SecondaryButton)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.coolGray.cool250};
  border-color: ${({ theme }) => theme.colors.coolGray.cool500};
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
`

const StyledClickToCopy = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.robotoCondensed};
  letter-spacing: normal;
  font-weight: bold;
  align-self: center;
  margin-bottom: 0;
  width: 70px;
  text-align: left;
`

const StyledCopyText = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  text-transform: none;
  letter-spacing: normal;
  background-color: ${({ theme }) => theme.colors.coolGray.cool400};
  border-color: ${({ theme }) => theme.colors.coolGray.cool400};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  margin-left: 8px;
  margin-right: 8px;
  align-self: center;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
`

const StyledCopyIcon = styled.img`
  height: 35px;
  width: 35px;
  align-self: center;
`

export interface ClickToCopyProps {
  copyText: string
}

const ClickToCopy: React.FC<ClickToCopyProps> = ({ copyText }) => {
  const [copied, setCopied] = useState(false)
  const previousCopyTextRef = useRef('')
  const location = useLocation()
  const params = useMemo(() => {
    const url = new URL(copyText)
    return new URLSearchParams(url.search)
  }, [copyText])
  const sendGtagEvent = useCallback(
    (eventName: string, eventData = {}) => {
      if (copyText === previousCopyTextRef.current) {
        return
      }

      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', eventName, eventData)
      }
    },
    [copyText]
  )

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(copyText).then(() => {
      if (location.pathname.includes('/escript/build')) {
        sendGtagEvent('copy_escript', {
          escript_campaign: params.get('ec'),
          escript_version: params.get('v'),
          escript_link: copyText,
        })
      }
      previousCopyTextRef.current = copyText
      setCopied(true)
    })
  }, [copyText, location.pathname, params, sendGtagEvent])

  useEffect(() => {
    if (previousCopyTextRef.current === copyText) {
      return
    }
    setCopied(false)
  }, [copyText])

  const resetCopiedAlert = () => {
    setCopied(false)
  }

  return (
    <>
      <StyledCopyArea onClick={copyToClipboard}>
        <StyledClickToCopy>Click to copy</StyledClickToCopy>
        <StyledCopyText>{copyText}</StyledCopyText>
        <StyledCopyIcon src={copyIcon} />
      </StyledCopyArea>
      {copied && (
        <Alert type="success" onDismiss={resetCopiedAlert}>
          Link copied to clipboard!
        </Alert>
      )}
    </>
  )
}

export default ClickToCopy
